a, ion-router-link {
  text-decoration: underline;
}

ion-card {
  max-width: 50em;
  margin: auto;
  margin-top: .5em;
}

/* This is bad practice, (!important) but I'm not sure how else to override the ionic subclasses globally for this particular behavior. Decided just to use <br /> tags for now.*/
/* 
ion-card-content p + :is(h1, h2, h3, h4, h5, h6) {
  margin-top: 1em !important; 
} */

ion-title {
  text-align:center;
}

/* Added these rules to provide uniform font weight and style across input forms. */
ion-label{
  font-style: normal;
  font-weight: normal;
}

 /* Force text-wrap on <ion-select> https://github.com/ionic-team/ionic-framework/issues/17269 */
ion-select::part(placeholder),
ion-select::part(text) {
  white-space: normal !important;
}
/* Force text-wrap on <ion-select-option>'s when shown in an Ionic Alert */
ion-alert.select-alert {
  /* These widths are pretty arbitrary, so you're use-case(s) could required different widths: */
  --width: 33vw !important;
  --max-width: 66vw !important;
}
ion-alert.select-alert .select-interface-option .alert-radio-label,
ion-alert.select-alert .select-interface-option .alert-checkbox-label {
  white-space: normal !important;
}

